<template>
  <div class="my-box" v-loading="loading">
    <!-- 功能区域 -->
    <el-row>
      <el-card shadow="always">
        <el-form
          label-width="80px"
          class="topS"
          ref="seekData"
          :model="seekData"
        >
          <el-row :gutter="10">
            <el-col :span="1">
              <el-button @click="openpopup" v-if="showAdd">新增</el-button>
            </el-col>
            <el-col :span="3">
              <el-form-item label="品牌">
                <el-select
                  v-model="seekData.brandId"
                  @change="brandChange"
                  clearable
                  filterable
                  placeholder="请选择"
                >
                  <el-option
                    v-for="(item) in brandSelectData"
                    :key="item.name"
                    :label="item.brandName"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="门店">
                <el-select
                  v-model="seekData.hotelId"
                  clearable
                  filterable
                  placeholder="请选择"
                >
                  <el-option
                    v-for="(item) in hotelSelectData"
                    :key="item.name"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="项目编号" prop="projectId">
                <el-input v-model="seekData.projectId" clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="设备编号" prop="equimentId">
                <el-input v-model="seekData.equimentId" clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="2" class="reset-button">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button @click="reset">重置</el-button>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
    </el-row>
    <!-- 内容区域 -->
    <el-row>
      <el-card shadow="always">
        <div class="my-header-table">
          <el-table
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            :height="height"
            style="width: 100%"
            @selection-change="tab"
          >
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column
              prop="projectId"
              label="项目编号"
            ></el-table-column>
            <el-table-column prop="equimentId" label="设备ID"></el-table-column>
            <el-table-column prop="roomNo" label="房间号"></el-table-column>
            <el-table-column label="操作" width="280">
              <template slot-scope="scope">
                <el-button
                  type="danger"
                  size="mini"
                  icon="el-icon-delete"
                  v-if="showDelete"
                  @click="deleteHandle(scope.row)"
                >
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            background
            layout="prev, pager, next, sizes, total, jumper"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="seekData.pageSize"
            :current-page="seekData.pageNum"
            :total="total"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
          ></el-pagination>
        </div>
      </el-card>
    </el-row>

    <addView ref="add" @addViewClose="addViewClose" />
  </div>
</template>
<script>
import {
  getKooFeeApplianceList,
  deleteKooFeeAppliance,
  getBrandSelect,
  getHotelSelect,
} from "@/api";
import addView from "./addView";

export default {
  components: {
    addView,
  },
  data() {
    return {
      height: 0,
      selectList: [],
      loading: false,
      // 品牌列表数据
      tableData: [],
      // 查询的数据
      total: null,
      seekData: {
        projectId: null,
        equimentId: null,
        brandId: null,
        hotelId: null,
        pageSize: 10,
        pageNum: 1,
      },
      brandSelectData: null,
      hotelSelectData: null,
      query: {},
    };
  },
  beforeMount() {
    let h = document.documentElement.clientHeight || document.body.clientHeight;
    this.height = h - 240;
  },

  computed: {
    // 权限
    showAdd() {
      return this.$store.state.roleMenu.includes("koofee:app:add");
    },
    showDelete() {
      return this.$store.state.roleMenu.includes("koofee:app:delete");
    },
  },
  created() {
    // 调用初始化表格数据方法
    this.initialize();
    this.getList();
  },
  methods: {
    initialize() {
      getBrandSelect().then((res) => {
        if (res.data.data) {
          this.brandSelectData = res.data.data;
        }
      });
    },
    brandChange(id) {
      getHotelSelect(id).then((res) => {
        if (res.data.data) {
          this.seekData.hotelId = null;
          this.hotelSelectData = res.data.data;
        }
      });
    },
    tab(list) {
      this.selectList = list;
    },
    openpopup() {
      this.$refs.add.openSet();
    },

    // 初始化表格数据
    getList() {
      this.loading = true;
      getKooFeeApplianceList(this.seekData)
        .then((res) => {
          this.loading = false;
          if (res.status === 200) {
            this.tableData = res.data.data.rows;
            this.total = res.data.data.total;
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    deleteHandle(row) {
      let that = this;
      this.$confirm("确认删除？")
        .then((_) => {
          deleteKooFeeAppliance(row.id).then((res) => {
            that.getList();
          });
        })
        .catch((err) => {});
    },
    addViewClose() {
      this.getList();
    },
    // 查询按钮
    handleSearch() {
      this.seekData.pageNum = 1;
      this.query = Object.assign({}, this.seekData);
      this.getList();
    },
    // 重置按钮
    reset() {
      this.seekData.pageNum = 1;
      this.$refs.seekData.resetFields();
      this.handleSearch();
    },
    //分页
    handleCurrentChange(pageNum) {
      this.seekData.pageNum = pageNum;
      this.getList();
    },
    handleSizeChange(psize) {
      this.seekData.pageSize = psize;
      this.seekData.pageNum = 1;
      this.getList();
    },
  },
};
</script>
<style lang="scss" scoped>
.avatarImg {
  height: 40px;
}
.my-box {
  .el-row {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-col {
    border-radius: 4px;
  }
  .my-input {
    max-width: 209px;
  }
}
.checkBox {
  display: inline-block;
  width: 28%;
}
</style>
